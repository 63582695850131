var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProductSettingsList',{attrs:{"mode":"translation","translation":_vm.translation,"title":"Product List - Translation"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.products,"items-per-page":10,"pagination":"","loading":_vm.isLoading,"items-per-page-select":"","cleaner":"","sorter":"","hover":"","table-filter":"","fields":[
             { key:'imageUrl', _style: 'width: 60px', label: ''},
             { key:'sku', _style: 'width: 150px' }, 
             { key:'title', _classes: 'h6', label: 'Title / Description' }, 
             { key:'isActive', label:'Active', _style: 'width: 80px;'}
             ]},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"sku",fn:function(ref){
             var item = ref.item;
return [_c('td',[_c('div',{staticClass:"mt-0 mb-0"},[_c('span',{staticClass:"strong text-nowrap"},[_vm._v(_vm._s(item.sku))])]),(!item.allBonusesAvailable)?_c('CLink',{class:{'text-danger':true, 'opacity-1': !item.isActive},attrs:{"to":("/products/view/" + (item.sku) + "?rewards=true")}},[_c('CIcon',{staticStyle:{"transform":"translateY(-2px)"},attrs:{"name":"cil-gift"}}),_vm._v(" "),_c('strong',[_vm._v("!")])],1):_vm._e()],1)]}},{key:"imageUrl",fn:function(ref){
             var item = ref.item;
return [_c('ProductsListImageColumn',{attrs:{"rowData":item,"disabled":!item.isActive}})]}},{key:"title",fn:function(ref){
             var item = ref.item;
return [_c('td',{class:{ 'opacity-1': !item.hasTranslation}},[((!item.title))?_c('CBadge',{attrs:{"color":"light"}},[_vm._v("Title missing")]):_c('strong',[_vm._v(_vm._s(item.title))]),_c('br'),((!item.description))?_c('CBadge',{attrs:{"color":"light"}},[_vm._v("Description missing")]):_c('span',[_vm._v(_vm._s(item.description))])],1)]}},{key:"isActive",fn:function(ref){
             var item = ref.item;
return [_c('td',[((item.configured !== false))?_c('CBadge',{attrs:{"color":_vm.getBadge(item.isActive)}},[_vm._v(" "+_vm._s(item.isActive ? "Active" : "Disabled")+" ")]):_c('CBadge',{attrs:{"color":"warning"}},[_vm._v("Not configured")])],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }