<template>
    <ProductSettingsList mode='translation' :translation='translation' title='Product List - Translation'>
        <template v-slot:table>
            <CDataTable
                :items="products"
                :items-per-page="10"
                pagination
                :loading="isLoading"      
                items-per-page-select       
                cleaner
                sorter
                hover
                table-filter
                 :fields="[
                 { key:'imageUrl', _style: 'width: 60px', label: ''},
                 { key:'sku', _style: 'width: 150px' }, 
                 { key:'title', _classes: 'h6', label: 'Title / Description' }, 
                 { key:'isActive', label:'Active', _style: 'width: 80px;'}
                 ]"
                 @row-clicked='rowClicked'
                >
                <template #sku="{item}">
                    <td>
                        <div class="mt-0 mb-0"><span class="strong text-nowrap">{{item.sku}}</span></div>
                        <CLink v-if="!item.allBonusesAvailable" :to="`/products/view/${item.sku}?rewards=true`" 
                             :class="{'text-danger':true, 'opacity-1': !item.isActive}" 
                        ><CIcon name="cil-gift" style="transform: translateY(-2px)" /> <strong>!</strong></CLink>
                    </td>
                </template>
                <template #imageUrl="{item}">
                   <ProductsListImageColumn :rowData='item' :disabled="!item.isActive" />
                </template>
                <template #title="{item}">
                    <td :class="{ 'opacity-1': !item.hasTranslation}">
                        <CBadge v-if="(!item.title)" color="light">Title missing</CBadge><strong v-else>{{ item.title }}</strong><br />
                        <CBadge v-if="(!item.description)" color="light">Description missing</CBadge><span v-else>{{ item.description }}</span>
                    </td>
                </template>
                <template #isActive="{item}">
                    <td>
                    <CBadge v-if="(item.configured !== false)" :color="getBadge(item.isActive)">
                        {{ item.isActive ? "Active" : "Disabled" }}
                    </CBadge>
                    <CBadge color="warning" v-else>Not configured</CBadge>
                    </td>
                </template>                
            </CDataTable>
        </template>

    </ProductSettingsList>
</template>

<script>
import ProductSettingsList from '@/components/ProductSettingsList';
import ProductsListImageColumn from '@/components/ProductsListImageColumn';
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';

export default {
    props: {
        translation: String
    },
    components: {
        ProductSettingsList,
        ProductsListImageColumn
    },
    computed: {
        ...mapGetters({
            products: 'products/products',
            isLoading: 'products/loading',
            merchantId: 'merchantId' }
        )
    },
    methods: {
        ...mapActions({loadProducts: actionNames.LOAD_PRODUCTS_TRANSLATION}),
         rowClicked(item) {
            this.$router.push(`/products/view/${item.sku}?tl=${this.translation}`);
         },
         getBadge (status) {
            switch (status) {
                case true: return 'success';
                default: return 'secondary';
            }
        }
    },
    watch: {
        translation() {
            this.loadProducts({translation: this.translation});
        }
    },
    created() {
        if (this.merchantId != null) {
            this.loadProducts({translation: this.translation});
        }        
    }
}
</script>