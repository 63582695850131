<template>
    <div>
    <CNavbar expandable="sm" color="light" light>
        <CNavbarNav >
        <CNavItem active to="/products" :class="{strong: isOverview}" :disabled="isLoading"><CIcon name="cil-list-rich" size="sm" class="mb-1 mr-2" />Overview</CNavItem>
        <CDropdown 
            v-if="canViewChannel"
            in-nav
            icon="cil-list-rich"
            :class="{strong: isChannel}"
            :disabled='(isLoading)'
        >
            <template #toggler-content>
                <CIcon name="cil-factory" size="sm" class="mb-1 mr-2" />
                <span style="color:black;">Channels<template v-if="isChannel && channelLabel"> ({{ channelLabel }})</template></span>
            </template>
            <CDropdownItem v-for="item in channels" :key="item.value" :to="`/products/channel/${item.value}`" >{{item.label}}</CDropdownItem>
        </CDropdown>                
        <!-- Navbar dropdowns -->
        <CDropdown
            v-if="hasTranslations"
            toggler-text="Translations"
            in-nav
            :class="{strong: isTranslation}"
            :disabled="isLoading"
        >
            <template #toggler-content>
                <CIcon name="cil-globe-alt" size="sm" class="mb-1 mr-2" />
                <span style="color:black;">Translations<template v-if="isTranslation"> ({{ translation }})</template></span>
            </template>
            <CDropdownItem v-for="tl in translations" :key="tl" :to="`/products/translation/${tl}`">{{ tl }}</CDropdownItem>
        </CDropdown>
        <CNavItem v-if="canViewReward" active :class="{strong: isRewards}" to="/products/rewards" :disabled="isLoading"><CIcon name="cil-gift" size="sm" :class="{'text-danger': missingRewards, 'mb-1 mr-2': true}" /><span :class="{'text-danger': missingRewards}">Rewards</span></CNavItem>
        </CNavbarNav>        
    </CNavbar>
    <CAlert v-if="missingRewards" color="danger" class="py-1 px-2 mt-2 mb-0"><strong>Missing Rewards!</strong> Not all products have rewards or rewards are unavailable.</CAlert>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapPermissions, VIEW_REWARD, VIEW_CHANNEL } from '@/permissions';
export default {
    props: {
        mode: String,
        channel: String,
        translation: String    
    },
    data() {
        return {
            selectedMode: this.mode,
        }
    },
    methods: { },
    computed: {
        ...mapPermissions({
            canViewReward: VIEW_REWARD,
            canViewChannel: VIEW_CHANNEL
        }),
        ...mapGetters({
            channels: 'products/channels', 
            translations: 'products/translations',
            isLoading: 'products/loading',
            missingRewards: 'products/missingRewards'
        }),        
        channelLabel() {    
            const current = (this.channel && this.channels) ? this.channels.find(ch => ch.value == this.channel) : null;
            return current ? current.label : null;
        },
        hasTranslations() {
            return this.translations != null && this.translations.length > 1;
        },
        isOverview() { return this.selectedMode === 'overview' || !this.selectedMode },
        isChannel() { return this.selectedMode === 'channel' },
        isTranslation() { return this.selectedMode === 'translation'},
        isRewards() { return this.selectedMode === 'rewards'}
    },
    watch: {
        mode(newMode) {
            this.selectedMode = newMode;
        }
    }
}
</script>