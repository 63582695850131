<template>
<CCard style="min-height: 120px;"> 
    <CCardHeader class="pt-2">            
        <div class="clearfix mb-1">             
            <h4 class="pt-1 pb float-left">{{ title }}</h4>          
            <!-- <CIcon name="cilPlus" /> -->
            <CButton v-if="canEdit" color="primary" class="float-right" to="/products/upload"><CIcon name="cilPlus" class="mt-0 mb-1" />&nbsp;Add Products</CButton>
         </div>
        <ProductSettingsMenu :mode='mode' :channel='channel' :translation="translation" />            
    </CCardHeader>
    <CCardBody class="pt-1">     
        <slot v-if="!empty" name="table">            
        </slot>      
        <CAlert :show="!hasError && empty" color="info" class="mt-2 mb-0">
            No bonus products configured.&nbsp;<CLink to="/products/upload" class="strong text-primary">Click here to upload</CLink>.
            </CAlert>
            <CAlert :show="hasError" color="danger" class="mt-2 mb-0">
            An unknown error occured. Please consider logging out and in again.
            </CAlert>  
    </CCardBody>
</CCard>
</template>

<script>
import ProductSettingsMenu from '@/components/ProductSettingsMenu';
import { mapGetters } from 'vuex';
import { EDIT_PRODUCT, mapPermissions } from '@/permissions';

export default {
    components: {
        ProductSettingsMenu
    },
    props: {
        mode: String,
        channel: String,
        translation: String,
        title: String
    },
    computed: {
        ...mapPermissions({
            canEdit: EDIT_PRODUCT
        }),
        ...mapGetters({
            products:   'products/products', 
            isLoading:  'products/loading',
            hasError:   'products/hasError',
            merchantId: 'merchantId' }
        ),
        empty() {
            return !this.isLoading && (this.products != null) && (this.products.length == 0);
        }
    },
    data() {
        return {
            innerTitle: this.title ? this.title : 'Product Settings'
        }
    },
    created() { }
}
</script>
